const GraphQLRadioLogo = ({
  ...props
}: React.SVGAttributes<SVGElement>): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 31 32'
    width={31}
    height={32}
    {...props}
  >
    <path
      fill='#3B4C6A'
      fillOpacity='0.8'
      d='M24.826 27.045a2.653 2.653 0 0 0-2.048-.257l-5.4-9.312c.164-.164.315-.35.44-.563a2.656 2.656 0 0 0-.975-3.63 2.684 2.684 0 0 0-3.646.97 2.653 2.653 0 0 0 .436 3.218l-5.405 9.326a2.673 2.673 0 0 0-3.058 1.218 2.652 2.652 0 0 0 .975 3.63 2.682 2.682 0 0 0 3.646-.975c.125-.213.21-.221.272-.665h10.845c.173.887.6 1.268 1.246 1.636a2.68 2.68 0 0 0 3.646-.97 2.647 2.647 0 0 0-.974-3.626zm-15.45.43 5.415-9.335c.476.133.97.129 1.433 0l5.4 9.308c-.169.164-.32.35-.44.563-.13.217-.214.222-.276.665H10.063a2.678 2.678 0 0 0-.686-1.201z'
    />
    <path
      fill='#3B4C6A'
      fillRule='evenodd'
      d='M2.087 7.711C6.359.346 15.856-2.19 23.254 2.062 30.652 6.315 33.2 15.77 28.93 23.134c-.155.272-.322.528-.485.778l-.004.006a.802.802 0 0 1-1.117.226.794.794 0 0 1-.224-1.112c.152-.23.297-.46.436-.7 3.826-6.6 1.548-15.068-5.082-18.882C15.818-.356 7.311 1.913 3.485 8.513c-2.642 4.56-2.476 10.123.437 14.526a.798.798 0 0 1-.23 1.11.805.805 0 0 1-1.116-.225C-.673 19.013-.863 12.8 2.087 7.71zm3.758 2.211c3.08-5.246 9.913-7.05 15.236-4.022 2.581 1.469 4.43 3.845 5.2 6.684.766 2.821.374 5.77-1.097 8.304a.807.807 0 0 1-.697.403.803.803 0 0 1-.698-1.204l.002-.001a9.188 9.188 0 0 0 .937-7.084c-.66-2.427-2.238-4.452-4.447-5.709-4.561-2.592-10.41-1.049-13.04 3.437a9.305 9.305 0 0 0-.017 9.356.8.8 0 0 1-.295 1.096l-.002.002a.81.81 0 0 1-1.1-.295 10.91 10.91 0 0 1 .018-10.967zm13.068-.374c-3.258-1.867-7.43-.754-9.31 2.484a6.748 6.748 0 0 0-.51 5.686l.002.003c.121.324.428.53.759.53a.851.851 0 0 0 .27-.049l.004-.001a.801.801 0 0 0 .486-1.026A5.163 5.163 0 0 1 11 12.83c1.436-2.47 4.623-3.324 7.108-1.895a5.16 5.16 0 0 1 2.29 6.238.798.798 0 0 0 .483 1.028.803.803 0 0 0 1.032-.482 6.739 6.739 0 0 0-.006-4.629 6.775 6.775 0 0 0-2.994-3.542z'
      clipRule='evenodd'
      opacity='.4'
    />
  </svg>
)

export default GraphQLRadioLogo
