import NextHead from 'next/head'

interface HeadProps {
  title?: string | null
  description?: string | null
  /**
   * Do not use a webp image for this prop as this format is not supported for og:image.
   */
  imageSrc?: string | null
  imageWidth?: number | null
  imageHeight?: number | null
  children?: React.ReactNode
}

export default function Meta({
  title,
  description,
  imageSrc,
  imageWidth,
  imageHeight,
  children,
}: HeadProps) {
  if (imageSrc?.endsWith('.webp') && process.env.NODE_ENV === 'development') {
    throw new Error(
      'Meta imageSrc should not use the .webp format (not supported by og:image)',
    )
  }
  return (
    <NextHead>
      {title && (
        <>
          <title>{title}</title>
          <meta name='twitter:title' content={title} key='twitter:title' />
          <meta property='og:title' key='og:title' content={title} />
        </>
      )}

      {description && (
        <>
          <meta name='description' key='description' content={description} />
          <meta
            name='twitter:description'
            key='twitter:description'
            content={description}
          />
          <meta
            property='og:description'
            key='og:description'
            content={description}
          />
        </>
      )}

      {imageSrc && (
        <>
          <meta name='image' key='image' content={imageSrc} />
          <meta name='twitter:image' key='twitter:image' content={imageSrc} />
          <meta property='og:image' key='og:image' content={imageSrc} />
          <meta
            name='twitter:card'
            key='twitter:card'
            content='summary_large_image'
          />
          {typeof imageWidth === 'number' && (
            <meta
              property='og:image:width'
              key='og:image:width'
              content={imageWidth.toString()}
            />
          )}
          {typeof imageHeight === 'number' && (
            <meta
              property='og:image:height'
              key='og:image:height'
              content={imageHeight.toString()}
            />
          )}
        </>
      )}

      {/* props.children must be at the end so users can override everything */}
      {children}
    </NextHead>
  )
}
