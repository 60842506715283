const GraphQLWeeklyLogo = ({
  ...props
}: React.SVGAttributes<SVGElement>): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width={32}
    height={32}
    {...props}
    fill='none'
  >
    <path
      d='M16 32C19.1645 32 22.2579 31.0616 24.8891 29.3035C27.5203 27.5454 29.5711 25.0466 30.7821 22.1229C31.9931 19.1993 32.3099 15.9823 31.6926 12.8786C31.0752 9.77487 29.5513 6.92394 27.3137 4.6863C25.0761 2.44866 22.2251 0.924806 19.1214 0.307443C16.0177 -0.309921 12.8007 0.0069325 9.87706 1.21793C6.95345 2.42894 4.45459 4.4797 2.69649 7.11088C0.938384 9.74207 0 12.8355 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32Z'
      fill='#3B4C6A'
      fillOpacity='0.8'
    />
    <path
      d='M23.5 13H8.5V22.2H23.5V13Z'
      stroke='white'
      strokeWidth='1.25'
      strokeMiterlimit='10'
    />
    <path
      d='M16 7.30005L23.5 13L16 18.4L8.5 13L16 7.30005Z'
      stroke='white'
      strokeWidth='1.25'
      strokeMiterlimit='10'
    />
    <path
      d='M15.9998 20.1C16.9387 20.1 17.6998 19.3388 17.6998 18.4C17.6998 17.4611 16.9387 16.7 15.9998 16.7C15.0609 16.7 14.2998 17.4611 14.2998 18.4C14.2998 19.3388 15.0609 20.1 15.9998 20.1Z'
      fill='white'
    />
    <path
      d='M23.4998 23.9C24.4387 23.9 25.1998 23.1389 25.1998 22.2C25.1998 21.2611 24.4387 20.5 23.4998 20.5C22.5609 20.5 21.7998 21.2611 21.7998 22.2C21.7998 23.1389 22.5609 23.9 23.4998 23.9Z'
      fill='white'
    />
    <path
      d='M8.4998 23.9C9.43869 23.9 10.1998 23.1389 10.1998 22.2C10.1998 21.2611 9.43869 20.5 8.4998 20.5C7.56092 20.5 6.7998 21.2611 6.7998 22.2C6.7998 23.1389 7.56092 23.9 8.4998 23.9Z'
      fill='white'
    />
    <path
      d='M8.4998 14.7C9.43869 14.7 10.1998 13.9389 10.1998 13C10.1998 12.0612 9.43869 11.3 8.4998 11.3C7.56092 11.3 6.7998 12.0612 6.7998 13C6.7998 13.9389 7.56092 14.7 8.4998 14.7Z'
      fill='white'
    />
    <path
      d='M15.9998 9.10996C16.9387 9.10996 17.6998 8.34884 17.6998 7.40996C17.6998 6.47108 16.9387 5.70996 15.9998 5.70996C15.0609 5.70996 14.2998 6.47108 14.2998 7.40996C14.2998 8.34884 15.0609 9.10996 15.9998 9.10996Z'
      fill='white'
    />
    <path
      d='M23.4998 14.7C24.4387 14.7 25.1998 13.9389 25.1998 13C25.1998 12.0612 24.4387 11.3 23.4998 11.3C22.5609 11.3 21.7998 12.0612 21.7998 13C21.7998 13.9389 22.5609 14.7 23.4998 14.7Z'
      fill='white'
    />
  </svg>
)

export default GraphQLWeeklyLogo
