import { FeatureListItem } from 'components/marketing/FeatureList'
import { networkLocations } from 'components/Map'

export const CAREERS_SLUG = '/careers'
export const CASE_STUDY_SLUG = '/case-study'
export const USE_CASES_SLUG = '/use-cases'

export const FEATURE_RATE_LIMITING = 'GraphQL Security'
export const FEATURE_METRICS = 'GraphQL Metrics'
export const FEATURE_EDGE_CACHING = 'GraphQL Edge Caching'

export const servicesTooltip: Tooltip = {
  title: 'What is a service?',
  description:
    'A service is a Stellate gateway pointed at one GraphQL API, which enables caching and analytics for your schema.',
}

export const EnterprisePlan: PricingPlan = {
  name: 'Enterprise',
  bgColor: 'neutral',
  description: 'For large companies',
  price: 'Individual pricing',
  buttonText: 'Contact us',
  buttonHref: 'mailto:sales@stellate.co?subject=Enterprise%20contract',
  footnote: 'Individual deals for every scale',
  features: {
    base: {
      monthlyRequests: 'Volume discounts',
      numberOfSeats: 'Custom',
      numberOfServices: 'Custom',
    },
    general: {
      locationsWorldwide: networkLocations.length,
      customDomains: 'Unlimited',
      environments: 'Unlimited',
      configurationCli: true,
      localDevelopment: true,
    },
    graphQlEdgeCaching: {
      fineGrainedConfiguration: true,
      expiryBasedInvalidation: true,
      automaticMutationInvalidation: true,
      purgingApi: true,
      purgingIde: true,
      operationLevelCacheRulesAnalysis: true,
      maxCacheHitRateEstimation: true,
    },
    graphQlMetrics: {
      cacheHitRateAnalysis: true,
      performanceMonitoring: true,
      performanceAlerts: true,
      errorTracking: true,
      errorAlerts: true,
      emailAlerts: true,
      slackAlerts: true,
      schemaUsageTracking: true,
      customAttributesTracking: true,
      maxCacheHitRateEstimation: true,
      retention: 'Custom',
    },
    graphQlRateLimiting: {
      requestCountLimit: true,
      complexityLimit: true,
      operationDependentRules: true,
      customConsumerId: true,
      realTime: true,
    },
    support: {
      emailSupport: true,
      communitySupport: true,
      liveChatSupport: true,
      uptimeSla: true,
      singleSignOn: true,
      dataProcessingAgreement: true,
    },
  },
}

export const edgeCachingPlusMetricsFeatures: FeatureListItem[] = [
  {
    icon: 'speedometer',
    title: 'Understand your global latency',
    description:
      'Most GraphQL APIs sit in one data center. Monitor your global latency and verify the performance boost your users get through edge caching.',
    imageSrc: '/features/global-latency.svg',
    imageAlt:
      'Map showing P95 latencies for North America (512ms), South America (1208ms), Europe (159ms) and Africa (747ms)',
    imageWidth: 572,
    imageHeight: 443,
  },
  {
    icon: 'insights',
    title: 'Get cache hit rate insights',
    description:
      'Understand how the attributes of your various data types affect the cache hit rate of individual queries.',
    imageSrc: '/features/cache-hit-rate-insights.svg',
    imageAlt:
      'A card where we see Different types of our application with their corresponding cache hit rate on the left side, on the right side there are different unused types and when they were last seen',
    imageWidth: 572,
    imageHeight: 443,
  },
  {
    icon: 'monitor',
    title: 'Monitor how your Cache Hit Rate changes',
    description:
      'Understand your caching strategy by observing Cache Hit Rate over time. Analyze trends, identify patterns, and adapt your cache rules to boost your API caching performance.',
    imageSrc: '/features/cache-hit-rate-chart.svg',
    imageAlt:
      'A card where we see the Cache Hit Rate chart over a period of time',
    imageWidth: 578,
    imageHeight: 443,
  },
  {
    icon: 'optimize',
    title: 'Optimize your cache rules performance',
    description:
      'Evaluate cache rules performance. Optimize your caching strategy by understanding how requests and operations are affected by your cache rules.',
    imageSrc: '/features/cache-rules.svg',
    imageAlt: 'Two cache rules showing the details and the affected types',
    imageWidth: 578,
    imageHeight: 439,
  },
  {
    icon: 'purging',
    title: 'Check your purging analytics',
    description:
      'Whether auto invalidated from mutations or manually invalidated from the API, Metrics show when and what is being purged from the cache.',
    imageSrc: '/features/analytics-purging.svg',
    imageAlt:
      'A statistic showing that a mutation named updateOrder resulted in an expected latency increase of 1537ms because it invalidated a set of types and queries',
    imageWidth: 572,
    imageHeight: 443,
  },
  {
    icon: 'debugging',
    title: 'Become a pro in cache debugging',
    description:
      'Track every request and its cache state.  Monitor your cache hit rate and understand why certain requests are being cached and others are not.',
    imageSrc: '/features/cache-debugging.svg',
    imageAlt:
      'A query named getCategories resulted in a cache-pass because the request body was too big, we can also derive that we retried the request three times, it it failed twice due to a Bad Gateway error and ended up in a success.',
    imageWidth: 572,
    imageHeight: 443,
  },
  {
    icon: 'cache-purging',
    title: 'One-Click Cache Purging',
    description:
      "Stay in control of content changes even if you can't rely on webhooks. Instantly purge the entire cache or use Purge actions to remove cache items based on specific operations or types from our metrics dashboard.",
    imageSrc: '/features/cache-purging.svg',
    imageAlt:
      'A query named caseStudyCollection is displayed accompanied by a P99 latency of 103ms alongside a Purge button that allows you to purge the cache for this query',
    imageWidth: 578,
    imageHeight: 442,
  },
]
