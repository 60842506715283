import { useIsomorphicLayoutEffect } from 'utils/useIsomorphicLayoutEffect'
import { splitbee } from '../utils/splitbee'
import { useUserContext } from './UserProvider'

export const InitSplitbee = () => {
  const { user } = useUserContext()

  useIsomorphicLayoutEffect(() => {
    if (!user) {
      return
    }

    splitbee.user.set({ userId: user.email })
  }, [user])

  return null
}
