import IconRenderer, { Icon } from './IconRenderer'

const socialChannels: { icon: Icon; link: string }[] = [
  {
    icon: 'twitter',
    link: 'https://twitter.com/stellate',
  },
  {
    icon: 'github',
    link: 'https://github.com/StellateHQ',
  },
  {
    icon: 'instagram',
    link: 'https://www.instagram.com/stellatehq',
  },
  {
    icon: 'linkedin',
    link: 'https://www.linkedin.com/company/stellatehq',
  },
]

interface SocialChannelsProps extends React.HTMLAttributes<HTMLUListElement> {
  /**
   * Defaults to 'lg'
   */
  size?: 'sm' | 'lg'
}

const SocialChannels = ({ size, className, ...props }: SocialChannelsProps) => {
  return socialChannels.length > 0 ? (
    <ul
      role='list'
      className={`flex ${
        size === 'sm' ? 'space-x-4' : 'space-x-5 sm:space-x-6'
      } ${className || ''}`}
      {...props}
    >
      {socialChannels.map(({ icon, link }) => {
        return (
          <li key={icon}>
            <a
              href={link}
              target='_blank'
              rel='noreferrer'
              className='min-clickable-area text-neutral-40 transition-colors hover:text-neutral-60 focus:text-neutral-60'
              aria-label={icon}
            >
              <IconRenderer
                icon={icon}
                className={`${
                  size === 'sm' ? 'h-5 sm:h-6' : 'h-8 sm:h-10'
                } w-auto`}
              />
            </a>
          </li>
        )
      })}
    </ul>
  ) : null
}

export default SocialChannels
