// TODO: optimize this, this currently includes all icons everywhere
import Alert from 'public/icons/alert.svg'
import BlockchainWeb3 from 'public/icons/blockchain-web3.svg'
import CachePurging from 'public/icons/cache-purging.svg'
import Coaching from 'public/icons/coaching.svg'
import Collaboration from 'public/icons/collaboration.svg'
import Conferences from 'public/icons/conferences.svg'
import Controls from 'public/icons/controls.svg'
import CostReduction from 'public/icons/cost-reduction.svg'
import CustomAttributes from 'public/icons/custom-attributes.svg'
import CustomGraphqlApi from 'public/icons/custom-graphql-api.svg'
import CustomerSuccess from 'public/icons/customer-success.svg'
import Debugging from 'public/icons/debugging.svg'
import DebugIssues from 'public/icons/debug-issues.svg'
import Design from 'public/icons/design.svg'
import DeveloperRelations from 'public/icons/developer-relations.svg'
import Development from 'public/icons/development.svg'
import Diamond from 'public/icons/diamond.svg'
import Domino from 'public/icons/domino.svg'
import Documentation from 'public/icons/documentation.svg'
import Ecommerce from 'public/icons/ecommerce.svg'
import Empathy from 'public/icons/empathy.svg'
import Engineering from 'public/icons/engineering.svg'
import Equipment from 'public/icons/equipment.svg'
import Excellence from 'public/icons/excellence.svg'
import Funnel from 'public/icons/funnel.svg'
import Hasura from 'public/icons/hasura.svg'
import HealthBenefits from 'public/icons/health-benefits.svg'
import Inclusion from 'public/icons/inclusion.svg'
import IncreasedPerformance from 'public/icons/increased-performance.svg'
import InfinityIcon from 'public/icons/infinity.svg'
import Insights from 'public/icons/insights.svg'
import Intentionality from 'public/icons/intentionality.svg'
import Invalidation from 'public/icons/invalidation.svg'
import Lock from 'public/icons/lock.svg'
import Marketing from 'public/icons/marketing.svg'
import Meal from 'public/icons/meal.svg'
import Monitor from 'public/icons/monitor.svg'
import NewsMedia from 'public/icons/news-media.svg'
import Notifications from 'public/icons/notifications.svg'
import OpenCommunication from 'public/icons/open-communication.svg'
import OperationsCardinality from 'public/icons/operations-cardinality.svg'
import Optimize from 'public/icons/optimize.svg'
import Other from 'public/icons/other.svg'
import ParentalLeave from 'public/icons/parental-leave.svg'
import PeopleAndOperations from 'public/icons/people-&-operations.svg'
import PerformanceMonitoring from 'public/icons/performance-monitoring.svg'
import Plugin from 'public/icons/plugin.svg'
import Plus from 'public/icons/plus.svg'
import Product from 'public/icons/product.svg'
import Purging from 'public/icons/purging.svg'
import ReferralFees from 'public/icons/referral-fees.svg'
import RetirementPlan from 'public/icons/retirement-plan.svg'
import Sales from 'public/icons/sales.svg'
import Search from 'public/icons/search.svg'
import ServerLoadReduction from 'public/icons/server-load-reduction.svg'
import ShootingStar from 'public/icons/shooting-star.svg'
import StarSpecial from 'public/icons/star-special.svg'
import Star from 'public/icons/star.svg'
import Speedometer from 'public/icons/speedometer.svg'
import Target from 'public/icons/target.svg'
import TimeOff from 'public/icons/time-off.svg'
import Transformation from 'public/icons/transformation.svg'
import Travel from 'public/icons/travel.svg'
import Trophy from 'public/icons/trophy.svg'
import Wordpress from 'public/icons/wordpress.svg'
// Social Channel Icons
import Github from 'public/icons/social-logos/github.svg'
import Instagram from 'public/icons/social-logos/instagram.svg'
import Linkedin from 'public/icons/social-logos/linkedin.svg'
import Twitter from 'public/icons/social-logos/twitter.svg'

const AvailableIcons = {
  alert: Alert,
  'blockchain-web3': BlockchainWeb3,
  'cache-purging': CachePurging,
  coaching: Coaching,
  collaboration: Collaboration,
  conferences: Conferences,
  controls: Controls,
  'cost-reduction': CostReduction,
  'custom-attributes': CustomAttributes,
  'custom-graphql-apis': CustomGraphqlApi,
  'customer-success': CustomerSuccess,
  debugging: Debugging,
  'debug-issues': DebugIssues,
  design: Design,
  'developer-relations': DeveloperRelations,
  development: Development,
  diamond: Diamond,
  domino: Domino,
  documentation: Documentation,
  ecommerce: Ecommerce,
  empathy: Empathy,
  engineering: Engineering,
  equipment: Equipment,
  excellence: Excellence,
  funnel: Funnel,
  hasura: Hasura,
  'health-benefits': HealthBenefits,
  inclusion: Inclusion,
  'increased-performance': IncreasedPerformance,
  infinity: InfinityIcon,
  insights: Insights,
  intentionality: Intentionality,
  invalidation: Invalidation,
  lock: Lock,
  marketing: Marketing,
  meal: Meal,
  monitor: Monitor,
  'news-media': NewsMedia,
  notifications: Notifications,
  optimize: Optimize,
  'open-communication': OpenCommunication,
  'operations-cardinality': OperationsCardinality,
  other: Other,
  'parental-leave': ParentalLeave,
  'people-&-operations': PeopleAndOperations,
  'performance-monitoring': PerformanceMonitoring,
  plugin: Plugin,
  plus: Plus,
  product: Product,
  purging: Purging,
  'referral-fees': ReferralFees,
  'retirement-plan': RetirementPlan,
  sales: Sales,
  search: Search,
  'server-load-reduction': ServerLoadReduction,
  'shooting-star': ShootingStar,
  'star-special': StarSpecial,
  star: Star,
  speedometer: Speedometer,
  target: Target,
  'time-off': TimeOff,
  transformation: Transformation,
  travel: Travel,
  trophy: Trophy,
  wordpress: Wordpress,
  wpgraphql: Wordpress,
  // Social Channel Icons
  github: Github,
  instagram: Instagram,
  linkedin: Linkedin,
  twitter: Twitter,
}

export type Icon = keyof typeof AvailableIcons

interface IconRendererProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  icon: Icon
}

const IconRenderer = ({ icon, ...props }: IconRendererProps) => {
  const IconComponent = AvailableIcons[icon]
  return IconComponent ? <IconComponent aria-hidden='true' {...props} /> : null
}

export default IconRenderer
