import { default as ogSplitbee } from '@splitbee/web'

if (!process.env.STORYBOOK && process.env.STAGE === 'production') {
  ogSplitbee.init({
    // Cookie-less mode tradeoff:
    // - Upside: we don't need a cookie banner
    // - Downside: logged-out users aren't consistently identified, leading to them being tracked multiple times
    disableCookie: true,
    scriptUrl: '/bee.js',
    apiUrl: '/_hive',
  })
}

export const splitbee = ogSplitbee
process.env.STAGE === 'production'
  ? ogSplitbee
  : // fixes an error where tracking fails in staging
    {
      track: () => undefined,
      user: {
        set: () => undefined,
      },
    }
