export function LogoGray({
  ...props
}: React.SVGAttributes<SVGElement>): JSX.Element {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1259 392' {...props}>
      <path
        fill='#b1b7c3'
        fillRule='evenodd'
        d='m328.93 308.35.27-.16c6.07-3.64 10.5-9.49 12.4-16.33a27.819 27.819 0 0 0-2.23-20.42l-31.64-60.28L69.49 337.33l85.22 49.42a38.88 38.88 0 0 0 39.02 0l135.2-78.4ZM42.9 243.33 234.04 28.62 193.73 5.25a38.88 38.88 0 0 0-39.02 0l-135.2 78.4A39.077 39.077 0 0 0 5.23 98 39.292 39.292 0 0 0 0 117.6V147c0 18.18 3.82 36.16 11.21 52.76 7.39 16.6 18.19 31.44 31.68 43.56Z'
      />
      <path
        fill='#8993a4'
        fillRule='evenodd'
        d='M328.93 83.65 324.37 81 215.31 181.73l91.47 79.62c14.42 12.55 19.76 30.85 14.19 51.65l7.97-4.61c5.94-3.45 10.87-8.4 14.3-14.37a39.393 39.393 0 0 0 5.22-19.63V117.6c0-6.88-1.8-13.64-5.23-19.6a39.077 39.077 0 0 0-14.28-14.35ZM169.54 224.72 43.52 127.06C29.31 116 22.9 99.45 25.82 80l-6.3 3.65A39.077 39.077 0 0 0 5.24 98 39.199 39.199 0 0 0 0 117.6v156.8c0 6.89 1.79 13.66 5.22 19.63a39.11 39.11 0 0 0 14.3 14.37l36.1 20.93 113.93-104.61Z'
      />
      <path
        d='M527.56 286.11c37.51 0 58.2-20.68 58.2-48.1 0-60.6-88.01-41.84-88.01-78.4 0-12.75 9.86-22.36 27.9-22.36s28.86 11.54 29.82 27.9h27.9c-1.44-29.82-23.33-53.15-57.71-53.15s-55.79 20.44-55.79 47.13c0 59.88 88.01 42.32 88.01 79.6 0 12.26-9.86 22.12-30.06 22.12s-32.22-12.02-33.91-29.34h-27.9c1.44 31.5 24.29 54.59 61.56 54.59Zm127.56 0c6.49 0 11.3-1.2 14.43-2.4v-24.05c-2.4 1.2-5.29 1.68-8.42 1.68-10.82 0-18.04-6.25-18.04-19.72v-56.27h26.45v-23.81h-26.45v-31.26h-22.85v18.28c0 9.38-5.05 14.43-14.67 14.43h-8.66v22.36h19.96v58.44c0 26.69 16.11 42.32 38.24 42.32ZM802 219.74c0-34.63-25.49-61.08-57.96-61.08s-60.12 28.14-60.12 63.73 26.69 63.73 60.36 63.73c26.69 0 46.65-19 53.39-36.55h-27.41c-4.33 6.25-12.5 12.75-26.21 12.75-17.31 0-30.3-11.06-33.67-29.58h90.18c.72-2.65 1.44-7.21 1.44-12.99Zm-57.96-37.27c16.35 0 29.34 12.26 32.22 30.3h-65.89c3.37-18.52 17.07-30.3 33.67-30.3Zm109.07 100.76V114.89h-26.45v168.33h26.45Zm59.42 0V114.89h-26.45v168.33h26.45Zm77.45-124.57c-29.34 0-45.93 19.48-48.1 39.92h26.45c1.44-7.94 8.18-16.11 21.4-16.11s22.6 8.9 22.6 23.57v4.09h-19.48c-33.43 0-56.51 15.39-56.51 39.68 0 19.96 17.07 36.31 40.88 36.31 19.48 0 30.78-10.34 35.59-16.59h1.2l4.81 13.71h19.72v-75.03c0-29.58-18.76-49.54-48.58-49.54Zm-7.21 105.09c-13.23 0-19.96-6.73-19.96-16.11 0-11.06 9.62-18.52 30.3-18.52h19.24v7.7c0 14.43-11.54 26.93-29.58 26.93Zm129.35 22.36c6.49 0 11.3-1.2 14.43-2.4v-24.05c-2.4 1.2-5.29 1.68-8.42 1.68-10.82 0-18.04-6.25-18.04-19.72v-56.27h26.45v-23.81h-26.45v-31.26h-22.85v18.28c0 9.38-5.05 14.43-14.67 14.43h-8.66v22.36h19.96v58.44c0 26.69 16.11 42.32 38.24 42.32ZM1259 219.74c0-34.63-25.49-61.08-57.95-61.08s-60.12 28.14-60.12 63.73 26.69 63.73 60.36 63.73c26.69 0 46.65-19 53.39-36.55h-27.41c-4.33 6.25-12.5 12.75-26.21 12.75-17.31 0-30.3-11.06-33.67-29.58h90.18c.72-2.65 1.44-7.21 1.44-12.99Zm-57.95-37.27c16.35 0 29.34 12.26 32.22 30.3h-65.89c3.37-18.52 17.07-30.3 33.67-30.3Z'
        fill='#8993a4'
      />
    </svg>
  )
}
