const AnalyticsIconGrey = ({
  ...props
}: React.SVGAttributes<SVGElement>): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 88 92'
    width={88}
    height={92}
    {...props}
  >
    <path
      d='M87.5 42C87.5814 53.0279 84.0606 63.7809 77.4726 72.625C70.8846 81.4692 61.5896 87.921 51 91L41.56 49.94L3.76001 53.72C2.99299 53.7979 2.22542 53.5775 1.61656 53.1046C1.0077 52.6316 0.60428 51.9424 0.490012 51.18C0.162136 48.8007 -0.001584 46.4017 1.15497e-05 44C1.15497e-05 20.11 18.73 1 42 1C47.7069 1.01055 53.3515 2.18681 58.5876 4.45665C63.8238 6.72649 68.541 10.0421 72.45 14.2L77 10.5C84 19.25 87.5 29.91 87.5 42Z'
      fill='#3B4C6A'
      fillOpacity='0.8'
    />
    <mask
      id='mask0_1468:21767'
      style={{ maskType: 'alpha' }}
      maskUnits='userSpaceOnUse'
      x='0'
      y='1'
      width='88'
      height='90'
    >
      <path
        d='M87.5 42C87.5814 53.0279 84.0606 63.7809 77.4726 72.625C70.8846 81.4692 61.5896 87.921 51 91L41.56 49.94L3.76001 53.72C2.99299 53.7979 2.22542 53.5775 1.61656 53.1046C1.0077 52.6316 0.60428 51.9424 0.490012 51.18C0.162136 48.8007 -0.001584 46.4017 1.15497e-05 44C1.15497e-05 20.11 18.73 1 42 1C47.7069 1.01055 53.3515 2.18681 58.5876 4.45665C63.8238 6.72649 68.541 10.0421 72.45 14.2L77 10.5C84 19.25 87.5 29.91 87.5 42Z'
        fill='#9B1C62'
      />
    </mask>
    <g mask='url(#mask0_1468:21767)'>
      <g filter='url(#filter0_d_1468:21767)'>
        <path
          d='M87.5 42C87.5814 53.0279 84.0606 63.7809 77.4726 72.625C70.8846 81.4692 61.5896 87.921 51 91L39.5 41L77 10.5C84 19.25 87.5 29.91 87.5 42Z'
          fill='#F8FAFC'
          fillOpacity='0.5'
          shapeRendering='crispEdges'
        />
      </g>
    </g>
    <defs>
      <filter
        id='filter0_d_1468:21767'
        x='0.0679245'
        y='-24.293'
        width='126.866'
        height='159.364'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='4.63907' />
        <feGaussianBlur stdDeviation='19.716' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.17 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_1468:21767'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_1468:21767'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
)

export default AnalyticsIconGrey
