import fetch from 'node-fetch'

export const contentful = async ({
  query,
  variables,
}: {
  query: string
  variables?: Record<string, any>
}) => {
  const json = await fetch(`https://stellate-cms.stellate.sh`, {
    method: `POST`,
    headers: {
      Authorization: `Bearer ${process.env.NEXT_PUBLIC_CONTENTFUL_API_KEY}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  }).then((res) => res.json())

  return json
}
