import React, {
  createContext,
  useLayoutEffect,
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
  ComponentType,
} from 'react'
import decode from 'jwt-decode'
import { fromUnixTime, isBefore } from 'date-fns'

export type TokenPayload = {
  id: number
  email: string
  fullName: string
  image: string
  exp: number
  impersonator?: string
}

type AuthState = {
  user: TokenPayload | undefined
  token?: string
  refreshToken?: string
  /** Immediately sets token values in storage then updates state */
  setTokens: (arg: { token: string; refreshToken: string }) => void
  removeTokens: () => void
  logout: typeof logout
  login: typeof login
}

type Tokens = {
  token?: string
  refreshToken?: string
}

export const UserContext = createContext<AuthState>(null as any)

export const useUserContext = () => useContext(UserContext)

export const UserProvider = (props: any) => {
  const [state, setState] = useState<Tokens>({
    token: undefined,
    refreshToken: undefined,
  })

  // used to match props/text during hydration
  useEffect(() => {
    setState(getTokensSync())
  }, [])

  const user = useMemo<AuthState['user']>(
    () => (state.token ? decode(state.token) : undefined),
    [state.token],
  )

  const removeTokens = useCallback(() => {
    const storage = getStorage()

    storage?.removeItem('auth_token')
    storage?.removeItem('auth_refresh_token')

    setState({
      refreshToken: undefined,
      token: undefined,
    })
  }, [])

  const setTokens = useCallback<AuthState['setTokens']>((tokens) => {
    const storage = getStorage()

    storage?.setItem('auth_token', tokens.token)
    storage?.setItem('auth_refresh_token', tokens.refreshToken)

    setState(tokens)
  }, [])

  const value = useMemo(
    () => ({
      ...state,
      user,
      setTokens,
      login,
      logout,
      removeTokens,
    }),
    [state, user, setTokens, removeTokens],
  )

  return <UserContext.Provider {...props} value={value} />
}

export function withAuthGuard<P>(
  Component: ComponentType<P>,
): ComponentType<P> {
  const Wrapper = (props: P) => {
    const { login, refreshToken } = useUserContext()

    // Refresh token looks valid on the client side
    // GraphQL exchange may still redirect to login if refreshToken is blacklisted/invalid
    const isRefreshValid = useMemo(
      () => refreshToken && !isTokenExpired(refreshToken),
      [refreshToken],
    )

    useLayoutEffect(() => {
      if (isRefreshValid) {
        return
      }

      login()
    }, [isRefreshValid, login])

    // Render nothing while redirect takes place
    if (!isRefreshValid) {
      return null
    }

    return <Component {...(props as P & { children?: unknown })} />
  }
  Wrapper.displayName = `withAuthGuard(${Component.displayName})`
  return Wrapper
}

/**
 * Here are a bunch of utility functions for auth
 * operations that need to be handled outside of
 * React render logic.
 *
 * Where possible, prefer use of context provisioned functions
 */

/** Returns true if token is expired now. */
export const isTokenExpired = (token: string) =>
  isBefore(fromUnixTime(decode<{ exp: number }>(token).exp), new Date())

/**
 * Synchronously retreive tokens from storage.
 * Note: Retrieve these values from `useUserContext` instead, where possible.
 */
export const getTokensSync = (): Tokens => {
  const storage = getStorage()
  return {
    token: storage?.getItem('auth_token') ?? undefined,
    refreshToken: storage?.getItem('auth_refresh_token') ?? undefined,
  }
}

/** Remove auth from storage and blacklist refresh token */
export const logout = () => {
  const storage = getStorage()
  const refreshToken = getTokensSync().refreshToken

  storage?.removeItem('auth_token')
  storage?.removeItem('auth_refresh_token')

  window.location.href = `/api/auth/logout?refresh=${encodeURIComponent(
    refreshToken as string,
  )}`
}

/** Redirect to login page to (re-)authenticate; then return to current address */
export const login = () => {
  const storage = getStorage()

  // Ensure old tokens are cleared before redirecting
  storage?.removeItem('auth_token')
  storage?.removeItem('auth_refresh_token')

  const redirect = `${location.pathname}${location.search}`
  location.href = `/login?redirect=${encodeURIComponent(redirect)}`
}

const getStorage = () => {
  const isDemoSession = window.sessionStorage.getItem('demo_session') === 'true'
  return typeof window === 'undefined'
    ? undefined
    : isDemoSession
      ? window.sessionStorage
      : window.localStorage
}
