import List from './generated/list.json'
import type { CountryCodes, Pops } from './generated/types'
export type { CountryCodes, Pops } from './generated/types'

type FastlyPop = {
  code: Pops
  city: string
  country: string
  countryCode: CountryCodes
  latitude: number
  longitude: number
}

const TypedFastlyPops = List as FastlyPop[]

export default TypedFastlyPops
