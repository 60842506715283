import { useEffect, useState } from 'react'
import cx from 'classnames'
import Image from 'next/image'
import pops from '@stellate/fastly-pops'

export function Map({ className }: { className?: string }): JSX.Element {
  const [isMapLoaded, setMapLoaded] = useState(false)
  const [, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
    let map
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    import('datamaps').then((mod) => {
      setMapLoaded(true)

      if (!document.getElementById('map')) {
        return
      }

      const Datamap =
        !mod.default && typeof mod === 'function' ? mod : mod.default

      map = new Datamap({
        element: document.getElementById('map'),
        scope: 'world',
        projection: 'equirectangular',
        responsive: true,
        aspectRatio: 0.42,
        geographyConfig: {
          popupOnHover: !1,
          highlightOnHover: !1,
          borderColor: '#f8fafc',
        },
        fills: {
          single: '#608bd8',
          multiple: '#608bd8',
          planned: '#ffff',
          upgrade: '#608bd8',
          defaultFill: '#EBF0F3',
        },
        bubblesConfig: {
          borderWidth: 2,
          radius: Math.max(750, Math.min(window.innerWidth, 1000)) * 0.005,
          exitDelay: 0,
          animate: false,
          highlightFillColor: '#3b4c6a',
          highlightBorderColor: '#f8fafc',
          fillOpacity: 1,
        },
      })
      resize()
    })

    function resize() {
      map?.resize()
      map?.bubbles(networkLocations, {
        radius: Math.max(600, Math.min(window.innerWidth, 1000)) * 0.005,
        popupTemplate: function (e, l) {
          return ['<div class="map__hover-info">' + l.name + '</div>'].join('')
        },
      })
    }

    window.addEventListener('resize', resize)

    return () => {
      // Makes hot-reloading work
      const elem = document.getElementById('map')
      if (elem) elem.innerHTML = ''
      window.removeEventListener('resize', resize)
    }
  }, [])

  return (
    <div className={cx('relative', className && className)}>
      {/* Fallback to image until the map loads */}
      <Image
        alt='Map with our edge locations, this map contains all of the 68 Fastly Points Of Presence we are caching in'
        src='/edge-location-map.webp'
        width={1280}
        height={537}
      />
      <div
        className={`absolute top-0 left-0 bottom-0 right-0 w-full h-full ${
          isMapLoaded ? `bg-gray-50` : `bg-transparent`
        }`}
      >
        <div id='map' />
      </div>
    </div>
  )
}

export const networkLocations = pops.map((pop) => ({
  name: pop.city,
  latitude: pop.latitude,
  longitude: pop.longitude,
  fillKey: 'single',
}))
