const EdgeCachingIconGrey = ({
  ...props
}: React.SVGAttributes<SVGElement>): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 88 92'
    width={88}
    height={92}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M77.4882 51.45L44.1282 85.12L38.3982 90.93C37.8846 91.4589 37.1978 91.7853 36.4634 91.8496C35.7289 91.9139 34.9959 91.7117 34.3982 91.28L9.29822 73.43C8.94402 73.1788 8.64695 72.8555 8.42649 72.4814C8.20603 72.1073 8.06716 71.6909 8.019 71.2593C7.97084 70.8278 8.01448 70.3909 8.14705 69.9774C8.27961 69.5639 8.49812 69.1831 8.78822 68.86L31.1182 44C13.0582 22.45 10.9982 20.5 10.9982 20.5L39.9982 0L77.5982 39.36C79.1578 40.9928 80.0188 43.1695 79.9983 45.4273C79.9778 47.6852 79.0772 49.8459 77.4882 51.45Z'
      fill='#3B4C6A'
      fillOpacity='0.8'
    />
    <mask
      id='mask0_1468:21772'
      style={{ maskType: 'alpha' }}
      maskUnits='userSpaceOnUse'
      x='8'
      y='0'
      width='72'
      height='92'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M77.4882 51.45L44.1282 85.12L38.3982 90.93C37.8846 91.4589 37.1978 91.7853 36.4634 91.8496C35.7289 91.9139 34.9959 91.7117 34.3982 91.28L9.29822 73.43C8.94402 73.1788 8.64695 72.8555 8.42649 72.4814C8.20603 72.1073 8.06716 71.6909 8.019 71.2593C7.97084 70.8278 8.01448 70.3909 8.14705 69.9774C8.27961 69.5639 8.49812 69.1831 8.78822 68.86L31.1182 44C13.0582 22.45 10.9982 20.5 10.9982 20.5L39.9982 0L77.5982 39.36C79.1578 40.9928 80.0188 43.1695 79.9983 45.4273C79.9778 47.6852 79.0772 49.8459 77.4882 51.45Z'
        fill='#959CA9'
      />
    </mask>
    <g mask='url(#mask0_1468:21772)'>
      <g filter='url(#filter0_d_1468:21772)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10.998 20.5L39.998 0L77.598 39.36C79.1576 40.9928 80.0187 43.1695 79.9981 45.4273C79.9776 47.6852 79.0771 49.8459 77.488 51.45L44.118 85.14C44.118 85.14 54.378 72 45.168 60.91C14.048 23.38 10.998 20.5 10.998 20.5Z'
          fill='#F8FAFC'
          fillOpacity='0.5'
          shapeRendering='crispEdges'
        />
      </g>
    </g>
    <defs>
      <filter
        id='filter0_d_1468:21772'
        x='-28.434'
        y='-34.793'
        width='147.865'
        height='164.004'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='4.63907' />
        <feGaussianBlur stdDeviation='19.716' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.17 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_1468:21772'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_1468:21772'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
)

export default EdgeCachingIconGrey
