const RateLimitingIconGrey = ({
  ...props
}: React.SVGAttributes<SVGElement>): JSX.Element => (
  <svg
    width='25'
    height='26'
    viewBox='0 0 25 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <mask
      id='mask0_600_12821'
      style={{ maskType: 'alpha' }}
      maskUnits='userSpaceOnUse'
      x='1'
      y='0'
      width='22'
      height='26'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.907 5.51895V11.2833C22.907 11.9847 22.907 13.4204 22.3533 15.3194C21.7958 17.4966 20.7613 19.3072 19.5624 20.7849C19.5205 20.8414 19.4781 20.8981 19.4352 20.9548C19.4352 20.9548 19.4387 20.9455 19.4453 20.9276C17.1899 23.6438 14.3979 25.2063 13.0976 25.8355C12.6334 26.0601 12.1034 26.0545 11.6436 25.8208C9.26302 24.611 1.82812 20.1157 1.82812 11.1595V5.67063C1.82812 4.68726 2.53994 3.8505 3.5102 3.69046L10.0136 2.61774L10.6316 0L21.5504 3.63959C22.3605 3.90962 22.907 4.66502 22.907 5.51895Z'
        fill='#608BD8'
      />
    </mask>
    <g mask='url(#mask0_600_12821)'>
      <path
        d='M2.03125 6.30047C2.03125 5.065 3.13953 3.76037 4.35853 3.5593L13.3353 2.0786C13.7566 2.0091 14.1891 2.07617 14.5695 2.27L20.0865 5.08054C20.3679 5.22389 20.66 5.39382 20.7638 5.69207C20.9785 6.30854 21.254 7.89091 21.254 11.9526C21.254 13.2862 22.817 13.6542 22.5332 14.7868C21.085 21.2313 15.307 24.6312 13.2429 25.6589C12.7749 25.8919 12.2372 25.8903 11.7714 25.653C9.39024 24.44 2.03125 19.9727 2.03125 11.0901C2.03125 8.88116 2.03125 7.35496 2.03125 6.30047Z'
        fill='#3B4C6A'
        fillOpacity='0.45'
      />
      <g filter='url(#filter0_d_600_12821)'>
        <path
          d='M22.9788 11.2132V5.48466C22.9788 4.63604 22.4358 3.88533 21.6307 3.61698L10.7798 0L8.70041 9.27249C8.70041 9.27249 14.769 12.4396 18.4196 14.417C22.0702 16.3944 19.5709 20.7014 19.5709 20.7014C22.8248 16.9046 22.9788 12.4986 22.9788 11.2132Z'
          fill='#CBCFD8'
        />
      </g>
    </g>
    <defs>
      <filter
        id='filter0_d_600_12821'
        x='-0.200387'
        y='-7.85364'
        width='32.08'
        height='38.5029'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='1.04715' />
        <feGaussianBlur stdDeviation='4.4504' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.17 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_600_12821'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_600_12821'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
)

export default RateLimitingIconGrey
