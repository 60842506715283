import { Footer } from './Footer'

interface Props {
  children: JSX.Element
  className?: string
}

export function Layout({ children, className }: Props): JSX.Element {
  return (
    <div className={`font-sans ${className}`}>
      <div className='min-h-screen bg-gray-50'>{children}</div>
      <Footer />
    </div>
  )
}
