import { AppProps } from 'next/app'
import Head from 'next/head'
import dynamic from 'next/dynamic'
import localFont from 'next/font/local'
import React, { createContext, useEffect, useMemo } from 'react'
import { ModalProvider } from 'react-modal-hook'
import { Analytics } from '@vercel/analytics/react'

import { InitSplitbee } from '../components/InitSplitbee'
import { Layout } from '../components/Layout'

import { UserProvider, useUserContext } from '../components/UserProvider'
import Meta from '../components/Meta'
// A few of these aren't needed...
import '@joaocdvr/react-notion-x/src/styles.css'
import '../styles/tailwind.scss'
import '../styles/index.scss'
import '../styles/global.scss'
import '../styles/toggle.scss'
import '../styles/oauth.scss'
import '../styles/cookie-consent-banner.scss'
import '../styles/marketo.scss'
// Although these aren't global styles, next.js supports importing third party css
// inside components only from node_modules
import '@gcdn/graphiql/embedded/index.css'
import '@gcdn/graphiql/shared/response-info.css'
import { splitbee } from '../utils/splitbee'
import { contentful } from 'utils/contentful'
import { CaseStudy, CaseStudyGroup } from 'types/generated/contentful'
import { Fira_Code } from 'next/font/google'

const CrispWithNoSSR = dynamic(() => import('../components/Crisp'))

const ttCommons = localFont({
  src: '../public/fonts/TT Commons/tt_commons_pro_variable.woff2',
  display: 'swap',
  weight: '100 950',
  variable: '--font-tt-commons',
})

const firaCode = Fira_Code({
  display: 'swap',
  variable: '--font-fira-code',
  weight: ['400', '500'],
  subsets: ['latin'],
  preload: false,
})

const description = `Scale, inspect & protect your GraphQL API. Cache your queries at the edge, get insights into its usage and protect it from malicious queries.`
const image = 'https://stellate.co/social.png'

type Navigation = {
  industryUseCases: CaseStudyGroup[]
  technologyUseCases: CaseStudyGroup[]
  latestUseCase: CaseStudy
}

interface Props extends AppProps {
  navigation: Navigation
}

export const NavigationContext = createContext<Navigation>({} as Navigation)

const GTM_SCRIPT = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-5GFXMXL9');
`

const GOOGLE_CONSENT_MODE_SCRIPT = `
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments)
  }
  gtag("consent", "default", {
    ad_storage: "denied",
    analytics_storage: "denied",
    functionality_storage: "denied",
    personalization_storage: "denied",
    security_storage: "granted",
    wait_for_update: 500
  });
  gtag("set", "ads_data_redaction", true);
  gtag("set", "url_passthrough", true);
`

export default function MyApp({
  Component,
  pageProps,
  router,
  navigation,
}: Props) {
  useEffect(() => {
    const gclid = router.query.gclid
    if (gclid) {
      splitbee.track('Visit via ad-click', {
        source: 'Google',
        gclid: Array.isArray(gclid) ? gclid[0] : gclid,
      })
    }
  }, [router.query.gclid])

  useEffect(() => {
    // last resort if x-frame-options and CSP fails
    if (typeof window !== 'undefined' && top && top != window) {
      top.location = window.location
    }
  }, [])

  return (
    <UserProvider>
      <ModalProvider>
        <Meta
          title='Stellate - The GraphQL Edge Caching Company'
          description={description}
          imageSrc={image}
        />
        <Head>
          <link
            rel='icon'
            type='image/png'
            sizes='128x128'
            href='/favicon-128.png'
          />
          <link
            rel='icon'
            type='image/png'
            sizes='64x64'
            href='/favicon-64.png'
          />
          <link
            rel='icon'
            type='image/png'
            sizes='32x32'
            href='/favicon-32.png'
          />
          <link
            rel='icon'
            type='image/png'
            sizes='16x16'
            href='/favicon-16.png'
          />
          <link rel='apple-touch-icon' href='/apple-touch-icon.png' />
          <meta name='viewport' content='width=device-width, initial-scale=1' />
          <meta name='theme-color' content='#F8FAFC' />
          <meta property='og:type' content='website' />
          <meta
            property='og:url'
            content={`https://stellate.co${router.asPath}`}
          />
          {/* Twitter */}
          <meta property='twitter:card' content='summary_large_image' />

          {/* tracking scripts */}
          {process.env.NODE_ENV === 'production' &&
            !router.asPath.startsWith('/login/callback') && (
              <>
                {/* Google Consent Mode Script */}
                <script
                  id='google-consent-mode-script'
                  data-cookieconsent='ignore'
                  nonce='wg8mH/4XL+9SBJO08+iYDw=='
                  dangerouslySetInnerHTML={{
                    __html: GOOGLE_CONSENT_MODE_SCRIPT,
                  }}
                />

                {/* Google Tag Manager */}
                <script
                  id='gtm-script'
                  data-cookieconsent='ignore'
                  nonce='xnsA3nOAhoQpKxGo7h1wQQ=='
                  dangerouslySetInnerHTML={{
                    __html: GTM_SCRIPT,
                  }}
                />

                <CookiebotScript path={router.asPath} />
              </>
            )}
        </Head>
        {/** Outside of <head /> to avoid render-blocking - allthough this is actually unused on non dashboard pages */}
        {/* Google Tag Manager (noscript) */}
        {process.env.NODE_ENV === 'production' &&
          !router.asPath.startsWith('/login/callback') && (
            <noscript>
              <iframe
                data-cookieconsent='ignore'
                src='https://www.googletagmanager.com/ns.html?id=GTM-5GFXMXL9'
                height='0'
                width='0'
                style={{ display: 'none', visibility: 'hidden' }}
              />
            </noscript>
          )}
        <CrispWithNoSSR />
        <Layout className={`${ttCommons.variable} ${firaCode.variable}`}>
          <NavigationContext.Provider value={navigation}>
            <Component {...pageProps} />
          </NavigationContext.Provider>
        </Layout>
        <InitSplitbee />
        <Analytics />
      </ModalProvider>
    </UserProvider>
  )
}

MyApp.getInitialProps = async () => {
  const json = contentful({
    query: `
      query {
        caseStudyGroupCollection(order: slug_ASC) {
          items {
            title
            slug
            type
          }
        }

        caseStudyCollection(
          where: { highlighted: true }
          order: publishedAt_DESC
          limit: 1
        ) {
          items {
            slug
            improvementType
            increasedPerformance
            serverLoadReduction
            costReduction
            quote {
              author {
                company {
                  name
                  logo {
                    title
                    url
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
    `,
  })

  const fallback = contentful({
    query: `
      query {
        caseStudyCollection(order: publishedAt_DESC, limit: 1) {
          items {
            slug
            improvementType
            increasedPerformance
            serverLoadReduction
            costReduction
            quote {
              author {
                company {
                  name
                  logo {
                    title
                    url
                    width
                    height
                  }
                }
              }
            }
          }
        }}
        `,
  })

  const [response, fallbackResponse] = await Promise.all([json, fallback])

  const navigation: Navigation = {
    industryUseCases: response.data.caseStudyGroupCollection.items.filter(
      (caseStudyGroup) => caseStudyGroup.type === 'industry',
    ),
    technologyUseCases: response.data.caseStudyGroupCollection.items.filter(
      (caseStudyGroup) => caseStudyGroup.type === 'technology',
    ),
    latestUseCase: {
      cardStyle: 'stats-only',
      ...(response.data.caseStudyCollection.items[0] ??
        fallbackResponse.data.caseStudyCollection.items[0]),
    },
  }

  return { navigation }
}

const CookiebotScript = ({ path }) => {
  const ctx = useUserContext()

  return ctx?.user?.impersonator ||
    path.startsWith('/login/callback') ? null : (
    <script
      id='Cookiebot'
      src='https://consent.cookiebot.com/uc.js'
      data-cbid='4e94c45f-4499-4393-a88c-b6b103f73c5b'
      // enables auto-blocking of cookies
      type='text/javascript'
      async
    />
  )
}
